import React from "react";
import { EffectComposer, SSAO, SMAA } from "react-postprocessing";

export default function PostProcessing() {
  return (
    <>
      <EffectComposer multisampling={0}>
        <SSAO
          intensity={40}
          luminanceInfluence={0.2}
          radius={8}
          scale={0.5}
          bias={0.5}
          distanceThreshold={0.5}
          distanceFalloff={0.03}
          rangeFalloff={0.001}
        />
        <SMAA />
      </EffectComposer>
    </>
  );
}
