var AnimationManager = function (p5, duration) {
  this.duration = duration;
  this.p5 = p5;
  this.startMillis;
  this.endMillis;
  this.currentMillis;
  this.delta;
  this._init = false;
  this._running = false;
};
AnimationManager.prototype.start = function () {
  if(!this._running){
    this.startMillis = this.p5.millis();
    this.endMillis = this.startMillis + this.duration;
    this._init = true;
    this._running = true;
  }
};
AnimationManager.prototype.update = function () {
  if (!this._init) return 0;
  this.currentMillis = this.p5.millis();
  if (this.currentMillis > this.endMillis) {
    return 1;
  } else {
    this.delta = (this.currentMillis - this.startMillis) / this.duration;
    return this.delta;
  }
};

const _makeSingleNoise = (p5, vX, vY, vZ) => {
  if (vZ == 0) {
    return p5.noise(vX, vY);
  }
  return p5.noise(vX, vY, vZ);
};

const minmax = (val, min = 0, max = 1) => {
  return Math.min(Math.max(val, min), max);
}

const makeNoiseMap = (p5, parameters) => {
  let width = parameters.width || p5.width,
    height = parameters.height || p5.height,
    xOffset = parameters.xOffset || 0,
    yOffset = parameters.yOffset || 0,
    zOffset = parameters.zOffset || 0,
    noiseScalar = parameters.noiseScalar || 1,
    numChanels = parameters.numChanels || 1,
    noiseSeed = parameters.noiseSeed || 0,
    noiseDetail = parameters.noiseDetail || 2,
    xScalar = parameters.xScalar || 1,
    yScalar = parameters.yScalar || 1;

  p5.noiseDetail(noiseDetail, 0.65);
  p5.noiseSeed(noiseSeed);

  let chanSeparation = 100;
  let noiseMult = { x: noiseScalar / width, y: noiseScalar / height };

  let noiseMap = new Array(height);

  for (let y = 0; y < height; y++) {
    noiseMap[y] = new Array(width);
    for (let x = 0; x < width; x++) {
      const vX = (x + xOffset * width) * noiseMult.x * xScalar;
      const vY = (y + yOffset * height) * noiseMult.y * yScalar;
      const vZ = zOffset;
      if (numChanels > 1) {
        noiseMap[y][x] = new Array(numChanels);
        for (var nc = 0; nc < numChanels; nc++) {
          noiseMap[y][x][nc] = _makeSingleNoise(
            p5,
            vX,
            vY,
            vZ + nc * chanSeparation
          );
        }
      } else {
        noiseMap[y][x] = _makeSingleNoise(p5, vX, vY, vZ);
      }
    }
  }
  return noiseMap;
};

const getIndex = (p5, x, y) => {
  return (x + p5.width * y) * 4;
};
const getRed = (p5, x, y) => {
  const index = getIndex(p5, x, y);
  //   if (index > p5.pixels.length - 1) {
  //     console.error("could not get red ", x, y, index, p5.pixels.length);
  //     return -1;
  //   }
  return p5.pixels[index];
};
const getGreen = (p5, x, y) => {
  const index = getIndex(p5, x, y) + 1;
  //   if (index > p5.pixels.length - 1) {
  //     console.error("could not get green ", x, y, index, p5.pixels.length);
  //     return -1;
  //   }
  return p5.pixels[index];
};
const getBlue = (p5, x, y) => {
  const index = getIndex(p5, x, y) + 2;
  //   if (index > p5.pixels.length - 1) {
  //     console.error("could not get blue ", x, y, index, p5.pixels.length);
  //     return -1;
  //   }
  return p5.pixels[index];
};
const getBrightness = (p5, x, y) => {
  const index = getIndex(p5, x, y);
  //   if (index > p5.pixels.length - 3) {
  //     console.error("could not get brightness ", x, y, index, p5.pixels.length);
  //     return -1;
  //   }
  return (
    (p5.pixels[index + 0] + p5.pixels[index + 1] + p5.pixels[index + 2]) / 3
  );
};

const getBrightnessVisual = (p5, x, y) => {
  const index = getIndex(p5, x, y);
    return (0.299*p5.pixels[index] + 0.587*p5.pixels[index+1] + 0.114*p5.pixels[index+2]);
  }
const setColor = (p5, x, y, r, g, b) => {
  const index = getIndex(p5, x, y);
  //   if (index > p5.pixels.length - 3) {
  //     console.error("could not set color ", x, y, index, p5.pixels.length);
  //     return;
  //   }
  p5.pixels[index] = r;
  p5.pixels[index + 1] = g;
  p5.pixels[index + 2] = b;
};
const createSetup = (width, height, src, loadCallback) => {
  return (p5, canvasParentRef) => {
    p5.createCanvas(width, height).parent(canvasParentRef);
    p5.pixelDensity(1);
    p5.loadImage(src, (img) => {
      p5.image(img, 0, 0, width, height);
      //   if (loadCallback) {
      //     loadCallback(img);
      //   }
    });
  };
};

const createDraw = (draw, active) => {
  return !active ? draw : (p5) => {};
};

const loadPixelsAndIterate = (p5, func, dir = 1, options) => {
  p5.loadPixels();
  if(dir == -1){
    for (let y = p5.height-1; y >= 0; y--) {
      for (let x = 0; x < p5.width; x++) {
        func(x, y, options);
      }
    }
  } else {
    for (let y = 0; y < p5.height; y++) {
      for (let x = 0; x < p5.width; x++) {
        func(x, y, options);
      }
    }
  }

  p5.updatePixels();
};

const getBase64 = (id) => {
  return document.querySelector(`#${id} .p5Canvas`).toDataURL();
};

const wrap = (value, from, to) => {
  if (value > to) return to;
  if (value < from) return from;
  return value;
  if (value > to) return from;
  if (value < from) return to;
  return value;
  if (from > to) {
    const tmp = from;
    from = to;
    to = tmp;
  }
  const cycle = to - from;
  if (Math.abs(cycle) < 0.00001) {
    return to;
  }
  return value - cycle * Math.floor((value - from) / cycle);
};

function monteCarlo(p5, minimum, maximum) {
  while (true) {
    const r1 = p5.random(minimum, maximum);
    const prob = p5.map(r1, minimum, maximum, 0, 1);
    const r2 = p5.random(1);

    if (r2 < prob) {
      return r1;
    }
  }
}
export {
  getIndex,
  getRed,
  getGreen,
  getBlue,
  getBrightness,
  setColor,
  wrap,
  monteCarlo,
  createSetup,
  createDraw,
  loadPixelsAndIterate,
  getBase64,
  AnimationManager,
  makeNoiseMap,
  minmax,
  getBrightnessVisual,
};
