import create from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";
import config from "./config.json";
import { growthStages } from "./constants";

const [useStore, store] = create((set) => ({
  started: false,
  devMode: config.devMode,
  userId: null,
  userCount: 0,
  seedCount: 0,
  currentStep: 1,
  growthStage: 0,
  seed: null,
  selectedSeed: null,
  saen: null,
  keimen: null,
  verzweigen: null,
  kreuzen: null,
  bluehen: null,
  welken: null,
  videoA: false,
  videoB: false,
  videoC: false,
  videoD: false,
  videoE: false,
  videoF: false,
  videoG: false,
  videoH: false,
  videoJ: false,
  videoJ: false,
  videoK: false,
  videoL: false,
  hyperCam: false,
  saenActive: false,
  keimenActive: false,
  verzweigenActive: false,
  bluehenActive: false,
  kreuzenActive: false,
  welkenActive: false,
  scrollLocked: true,
  scrollLockedTimestamp: "",
  scrollLockedPosition: null,
  setStarted: (value) => set((state) => ({ started: value })),
  setUserId: (value) => set((state) => ({ userId: value })),
  setUserCount: (value) => set((state) => ({ userCount: value })),
  setSeedCount: (value) => set((state) => ({ seedCount: value })),
  setCurrentStep: (value) => set((state) => ({ currentStep: value })),
  setGrowthStage: (value) => set((state) => ({ growthStage: value })),
  setSeed: (value) =>
    set((state) => ({
      seed: value,
    })),
  setSelectedSeed: (value) =>
    set((state) => ({
      selectedSeed: value,
    })),
  setSaen: (value) =>
    set((state) => ({
      saen: value,
    })),
  setKeimen: (value) =>
    set((state) => ({
      keimen: value,
    })),
  setVerzweigen: (value) =>
    set((state) => ({
      verzweigen: value,
    })),
  setBluehen: (value) =>
    set((state) => ({
      bluehen: value,
    })),
  setKreuzen: (value) =>
    set((state) => ({
      kreuzen: value,
    })),
  setWelken: (value) =>
    set((state) => ({
      welken: value,
    })),
  setVideoA: (value) =>
    set((state) => ({
      videoA: value,
    })),
  setVideoB: (value) =>
    set((state) => ({
      videoB: value,
    })),
  setVideoC: (value) =>
    set((state) => ({
      videoC: value,
    })),
  setVideoD: (value) =>
    set((state) => ({
      videoD: value,
    })),
  setVideoE: (value) =>
    set((state) => ({
      videoE: value,
    })),
  setVideoF: (value) =>
    set((state) => ({
      videoF: value,
    })),
  setVideoG: (value) =>
    set((state) => ({
      videoG: value,
    })),
  setVideoH: (value) =>
    set((state) => ({
      videoH: value,
    })),
  setVideoI: (value) =>
    set((state) => ({
      videoI: value,
    })),
  setVideoJ: (value) =>
    set((state) => ({
      videoJ: value,
    })),
  setVideoK: (value) =>
    set((state) => ({
      videoK: value,
    })),
  setVideoL: (value) =>
    set((state) => ({
      videoL: value,
    })),
  setHyperCam: (value) => set((state) => ({ hyperCam: value })),
  setSaenActive: (value) =>
    set((state) => ({
      saenActive: value,
    })),
  setKeimenActive: (value) =>
    set((state) => ({
      keimenActive: value,
    })),
  setVerzweigenActive: (value) =>
    set((state) => ({
      verzweigenActive: value,
    })),
  setBluehenActive: (value) =>
    set((state) => ({
      bluehenActive: value,
    })),
  setKreuzenActive: (value) =>
    set((state) => ({
      kreuzenActive: value,
    })),
  setWelkenActive: (value) =>
    set((state) => ({
      welkenActive: value,
    })),
  setScrollLocked: (value, position = null) =>
    set((state) => ({
      scrollLocked: value,
      scrollLockedTimestamp: new Date(),
      scrollLockedPosition: position,
    })),
}));

// TODO: only if not devMode
mountStoreDevtool("Store", store);

export { useStore };
