
import React, { useEffect, useState } from 'react';

// == Import
import './styles.scss';


// == Composant
const VideoCursor = () => {

  return (
    <div className="video__time">
        <span className="video__time__cursor">
          <p>Enjoy</p>
        </span>
    </div>

  );
};

// == Export
export default VideoCursor;
