import React from "react";
import Sketch from "react-p5";
import { RGBA_ASTC_10x10_Format } from "three";
import { v4 as uuidv4 } from "uuid";
import Container from "../Container";
import {
  getIndex,
  getRed,
  getGreen,
  getBlue,
  getBrightness,
  setColor,
  createSetup,
  loadPixelsAndIterate,
  getBase64,
  wrap,
  AnimationManager,
  makeNoiseMap,
  minmax,
} from "../helpers";

let frameCounter = 0;
const id = "id_" + uuidv4();
var hasFinished = false;
/* p5 global vars */

let anMan;
let img1ref;
let noiseMap;
let zNoise;
let count = 0;

let noiseParams = {
  zOffset: 0,
  noiseScalar: 1,
  xScalar: 1,
  xOffset: 0,
  yOffset: 0,
  noiseDetail: 3,
  noiseSeed: Math.random() * 100,
};

function posMod(n, m) {
  return ((n % m) + m) % m;
}

function swapUp(src, x, y) {
  const index1 = getIndex(src, x, y);
  const index2 = getIndex(src, x, posMod(y + 1, src.height));
  const swap = [
    src.pixels[index1],
    src.pixels[index1 + 1],
    src.pixels[index1 + 2],
    src.pixels[index1 + 3],
  ];
  src.pixels[index1] = src.pixels[index2];
  src.pixels[index1 + 1] = src.pixels[index2 + 1];
  src.pixels[index1 + 2] = src.pixels[index2 + 2];
  src.pixels[index1 + 3] = src.pixels[index2 + 3];
  src.pixels[index2] = swap[0];
  src.pixels[index2 + 1] = swap[1];
  src.pixels[index2 + 2] = swap[2];
  src.pixels[index2 + 3] = swap[3];
}

function swapDown(src, x, y) {
  const index1 = getIndex(src, x, y);
  const index2 = getIndex(src, x, posMod(y - 1, src.height));
  const swap = [
    src.pixels[index1],
    src.pixels[index1 + 1],
    src.pixels[index1 + 2],
    src.pixels[index1 + 3],
  ];
  src.pixels[index1] = src.pixels[index2];
  src.pixels[index1 + 1] = src.pixels[index2 + 1];
  src.pixels[index1 + 2] = src.pixels[index2 + 2];
  src.pixels[index1 + 3] = src.pixels[index2 + 3];
  src.pixels[index2] = swap[0];
  src.pixels[index2 + 1] = swap[1];
  src.pixels[index2 + 2] = swap[2];
  src.pixels[index2 + 3] = swap[3];
}

function doSwap(x, y, options) {
  const no = noiseMap[y][x];
  const noz = no + zNoise -.5;
  if (noz < 0.4) {
    swapDown(options.src, x, y);
  } else if (noz > 0.5) {
    swapUp(options.src, x, y);
  }
}

/**
 * take two images, intersect them by noise, fake animate the process
 *
 * @param {boolean} active
 * @param {*} src
 * @param {int} width
 * @param {int} height
 * @param {function} onFinished
 * @param {int} runtime - milliseconds until animation is "finished"
 * @param {int} frameDivider - animation is called every nth frame
 * @param {int} noiseScalar - noise Size Scalar
 */
export default function P5({
  active,
  src,
  width,
  height,
  onFinished,
  runtime = 60000,
  frameDivider = 1,
}) {
  const preload = (p5) => {
    // fetch imgs, handle only one img somewhat gracefully
    img1ref = p5.loadImage(src);
  };

  const setup = (p5, canvasParentRef) => {
    // barebones p5
    // createSetup(width, height, src)(p5, canvasParentRef);
    p5.createCanvas(width, height).parent(canvasParentRef);

    p5.pixelDensity(1);

    // animation management
    anMan = new AnimationManager(p5, runtime);

    img1ref.loadPixels();
    p5.image(img1ref, 0, 0, width, height);
    p5.erase();
    p5.fill(255);
    p5.rect(0, height - 50, width, height);
    p5.noErase();
    noiseMap = makeNoiseMap(p5, noiseParams);
    // imgScale = {
    //   w: imageScale,
    //   h: img1ref.height*imageScale/img1ref.width
    // }
  };
  const draw = (p5) => {
    if (!active) return 0; // if not active skip block
    if (frameDivider != 1) {
      // check if framedivider is in effect
      frameCounter++;
      if (frameCounter % frameDivider != 0) {
        // if not on "active" frame return
        return 1;
      }
    }

    anMan.start();
    let animationHead = anMan.update();

    /* 
        creative part start
        */
    count += 0.002;
    zNoise = p5.noise(count);
    loadPixelsAndIterate(p5, doSwap, 1, { src: p5 });
    /*
        creative part end
        */

    if (animationHead >= 1 && !hasFinished) {
      hasFinished = true;
      // if past runtime trigger finished event
      if (onFinished) {
        onFinished(getBase64(id));
      }
      return 2;
    }
    return 3;
  };
  const debugDraw = (p5) => {
    // console.log({
    //   active,
    //   src,
    //   src2,
    // });
    // console.log(draw(p5));
    draw(p5);
  };

  return (
    <Container width={width} height={height} id={id}>
      <Sketch preload={preload} setup={setup} draw={debugDraw} />
    </Container>
  );
}
