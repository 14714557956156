import React from "react";

function KeyLight({ brightness, color }) {
  return (
    <rectAreaLight
      width={3}
      height={3}
      color={color}
      intensity={brightness}
      position={[-2, 0, 5]}
      lookAt={[0, 0, 0]}
      penumbra={1}
      castShadow
    />
  );
}
function FillLight({ brightness, color }) {
  return (
    <rectAreaLight
      width={3}
      height={3}
      intensity={brightness}
      color={color}
      position={[2, 1, 4]}
      lookAt={[0, 0, 0]}
      penumbra={2}
      castShadow
    />
  );
}
function RimLight({ brightness, color }) {
  return (
    <rectAreaLight
      width={2}
      height={2}
      intensity={brightness}
      color={color}
      position={[1, 4, -2]}
      rotation={[0, 180, 0]}
      castShadow
    />
  );
}

export default function Lights() {
  return (
    <>
      <hemisphereLight intensity={1} color="lightgreen" />
      {/* <KeyLight brightness={5.6} color="#ffbdf4" />{" "} */}
      <FillLight brightness={2.6} color="#bdefff" />{" "}
      {/* <RimLight brightness={54} color="#fff" /> */}
    </>
  );
}
