import React, { useEffect, useState } from "react";

// == Import
import "./styles.scss";
import $ from "jquery";
import { getAssetUrl } from "../../api";

// == Composant
const Backgrounds = () => {
  
  return (
    <div className="backgrounds" id="backgrounds">
        <div
            className="background__container"
            data-0="display:none"
            data-1000="display:block;opacity:0;"
            data-1010="display:block;opacity:0.4;"
            data-7001="display:block;opacity:0.4;" // Opacity Max, end
            data-10501="display:block;opacity:0.2;" // End of opacity change
            data-123001="display:block;opacity:0.1;"
            data-126001="display:block;opacity:0.1;"
            data-150001="display:block;opacity:0.1;"
            data-152001="display:block;opacity:0.2;"
            // data-7502="display:none" // end
        ><div className="intro__background-1 background--inner" /></div>
        <div
            className="background__container"
            data-0="display:none" // beginning
            data-5001="display:block;opacity:0;" // Start of opacity change
            data-9001="display:block;opacity:1;" // Opacity Max, beginning
            data-24001="display:block;opacity:1;" // Opacity Max, end
            data-27001="display:block;opacity:0;" // End of opacity change
            data-27002="display:none" // end
        ><div className="background__4 background--inner" /></div>
         <div
            className="background__container"
            data-0="display:none" // beginning
            data-23001="display:block;opacity:0;" // Start of opacity change
            data-27001="display:block;opacity:1;" // Opacity Max, beginning
            data-60001="display:block;opacity:1;" // Opacity Max, end
            data-63001="display:block;opacity:0;" // End of opacity change
            data-63002="display:none" // end
        ><div className="background__6 background--inner" /></div>
        <div
            className="background__container"
            data-0="display:none" // beginning
            data-57001="display:block;opacity:0;" // Start of opacity change
            data-63001="display:block;opacity:1;" // Opacity Max, beginning
            data-72001="display:block;opacity:1;" // Opacity Max, end
            data-77001="display:block;opacity:0;" // End of opacity change
            data-77002="display:none" // end
        ><div className="background__7 background--inner" /></div>

        <div
            className="background__container"
            data-0="display:none" // beginning
            data-71001="display:block;opacity:0;" // Start of opacity change
            data-77001="display:block;opacity:1;" // Opacity Max, beginning
            data-108001="display:block;opacity:1;" // Opacity Max, end
            data-109501="display:block;opacity:0;" // End of opacity change
            data-109502="display:none" // end
        ><div className="background__9 background--inner" /></div>
        
        <div
            className="background__container"
            data-0="display:none" // beginning
            data-109001="display:block;opacity:0;" // Start of opacity change
            data-111001="display:block;opacity:1;" // Opacity Max, beginning
            data-156001="display:block;opacity:1;" // Opacity Max, end
            data-157001="display:block;opacity:0;" // End of opacity change
            data-157002="display:none" // end
        ><div className="background__2 background--inner" /></div>

        {/* <div
            className="background__container"
            data-0="display:none" // beginning
            data-123001="display:block;opacity:0;" // Start of opacity change
            data-126001="display:block;opacity:1;" // Opacity Max, beginning
            data-150001="display:block;opacity:1;" // Opacity Max, end
            data-156001="display:block;opacity:0;" // End of opacity change
            data-156002="display:none" // end
        ><div className="background__10 background--inner" /></div> */}

        <div
            className="background__container"
            data-0="display:none" // beginning
            data-151001="display:block;opacity:0;" // Start of opacity change
            data-155001="display:block;opacity:1;" // Opacity Max, beginning
            data-195001="display:block;opacity:1;" // Opacity Max, end
            data-201001="display:block;opacity:0;" // End of opacity change
            data-201002="display:none" // end
        ><div className="background__5 background--inner" /></div>
        <div
            className="background__container"
            data-0="display:none" // beginning
            data-162001="display:block;opacity:0;" // Start of opacity change
            data-164001="display:block;opacity:1;" // Opacity Max, beginning
            data-168001="display:block;opacity:1;" // Opacity Max, end
            data-170001="display:block;opacity:0;" // End of opacity change
            data-170002="display:none" // end
        ><div className="background__8 background--inner" /></div>

        <div
            className="background__container"
            data-0="display:none" // beginning
            data-197001="display:block;opacity:0;" // Start of opacity change
            data-203001="display:block;opacity:1;" // Opacity Max, beginning
            data-250000="display:block;opacity:1;" // Opacity Max, end
            data-250001="display:block;opacity:0;" // Opacity Max, end
        ><div className="background__12 background--inner" /></div>
    </div>
  );
};

// == Export
export default Backgrounds;
