import React, { useState } from "react";
import styled from "styled-components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useStore } from "../../store";
import FPS from "./FPS";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: lightseagreen;
  color: black;
  z-index: 10000;
  display: flex;
`;

const Right = styled.div`
  text-align: right;
  flex-grow: 1;
`;

export default function HeaderBar() {
  const userId = useStore((state) => state.userId);
  const scrollLocked = useStore((state) => state.scrollLocked);
  const seed = useStore((state) => state.seed);
  const step = useStore((state) => state.currentStep);
  const growthStage = useStore((state) => state.growthStage);
  const [prev, setPrev] = useState(null);
  const [curr, setCurr] = useState(null);
  useScrollPosition(({ prevPos, currPos }) => {
    setPrev(prevPos);
    setCurr(currPos);
  });
  return (
    <Container>
      userId: {userId} :: scrollLocked: {scrollLocked ? 1 : 0}
      {", "}
      {prev && curr && (
        <>
          prev: {prev.y}, curr: {curr.y}, step: {step}, growthStage:{" "}
          {growthStage}
          {/* seed: {seed && seed.id},  */}
        </>
      )}
      <Right>
        <FPS></FPS>
      </Right>
    </Container>
  );
}
