import React from "react";
import { render } from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
} from "react-router-dom";

import App from "./components/App";
import Demo from "./components/Demo";
import Mobile from "./components/Mobile";
import PhotoBooth from "./components/PhotoBooth";
import { uploadSeed } from "./api";
import GardenClosed from "./components/GardenClosed";
import Error from "./components/404";
import config from "./config.json";

const open =
  config.alwaysOpen ||
  (new Date().getUTCHours() + 1 > 19 && new Date().getUTCHours() + 1 < 24);

// const open = (new Date().getUTCHours() + 1 > 18 && new Date().getUTCHours() + 1 < 24);

const rootComponent = (
  <Router>
    <Switch>
      <Route exact path="/">
        <Mobile />
        {open ? <App /> : <GardenClosed />}
        {/* Add this component for enabling opening hours */}
        {/* <GardenClosed /> */}
      </Route>
      <Route exact path="/session">
        <Mobile />
        {open ? <App /> : <GardenClosed />}
        {/* Add this component for enabling opening hours */}
        {/* <GardenClosed /> */}
      </Route>

      <Route path="/upload/:userId">
        <PhotoBooth
          uploadCallback={(data, userId) => {
            uploadSeed(data, userId, 0);
          }}
        />
      </Route>

      <Route exact path="/debug">
        <Demo></Demo>
      </Route>
      <Route path="/debug/:userId">
        <Demo></Demo>
      </Route>
      <Route path="/session/:userId">
        <Mobile />
        {open ? <App /> : <GardenClosed />}
      </Route>
      <Route>
        <Error></Error>
      </Route>
    </Switch>
  </Router>
);

const target = document.getElementById("root");

render(rootComponent, target);
