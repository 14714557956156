import React, { useEffect, useState, useRef } from "react";
import { useStore } from "../../store";
import HyperCam from "../HyperCam";
import config from "../../config.json";
import Downloader from "../Downloader";

import "./styles.scss";

const Vermodern = () => {
  const seedAmount = useStore((state) => state.seedCount);
  const setScrollLocked = useStore((state) => state.setScrollLocked);
  return (
    <section
      className="section section--vermodern"
      id="vermodern"
      data-0="display:none;pointer-events:none;position:fixed;top:0;left:0"
      data-236000="display:none;pointer-events:none;position:fixed;top:0;left:0"
      data-236001="display:block;pointer-events:all;position:fixed;top:0;left:0"
    >
      <div
        className="vermodern__title"
        data-end="238000"
        data-236000="bottom:0vh;opacity:0"
        data-236100="bottom:0vh;opacity:1"
        data-238000="bottom:40vh;opacity:1"
        data-238200="bottom:40vh;opacity:0"
      >
        Vermodern
      </div>

      <div
        className="vermodern__text"
        data-238000="display:none"
        data-238200="display:flex"
        data-240000="background-color:rgba(0,0,0,0)"
        data-240200="background-color:rgba(0,0,0,1)"
        data-241000="background-color:rgba(0,0,0,0)"
      >
        <p
          data-238200="opacity:0"
          data-238600="opacity:1"
          data-239000="opacity:0"
        >
          Werde Blume.
        </p>
        <p
          data-238600="opacity:0"
          data-239000="opacity:1"
          data-239400="opacity:0"
        >
          Werde digitales Blatt.
        </p>
        <p
          data-239000="opacity:0"
          data-239400="opacity:1"
          data-239800="opacity:0"
        >
          Werde lebhafte Materie.{" "}
        </p>
        <p
          data-239400="opacity:0"
          data-239800="opacity:1"
          data-240200="opacity:0"
        >
          Werde Humus für das kommende Ökosystem.
        </p>
      </div>
      <div
        className="vermordern__hypercam"
        data-240000="opacity:0"
        data-240100="opacity:1"
        data-255000="opacity:1"
        data-255001="opacity:0"
      >
        <HyperCam url={config.hyperCamUrl}></HyperCam>
      </div>

      <div
        className="vermoden__hcLeave"
        data-0="display:none;"
        data-241000="display:block;"
        data-255000="display:block;"
        data-255001="display:none;"
        onClick={() => {
          // document.querySelector("html").style.overflow = "scroll";
          setScrollLocked(false);
          window.scrollTo(0, 255010);
        }}
      >
        <button>CREDITS</button>
      </div>
      <div
        className="vermodern__end"
        data-255000="display:none"
        data-255001="display:flex"
      >
        <span>
          Thank you for becoming part <br />
          of the Coming Ecosystem.
        </span>
        <div className="credits">
          Idea, concept: doublelucky productions
          <br />
          Text, voice: Christiane Kühl
          <br />
          Video, image processing: Chris Kondek
          <br />
          Composition, music: Hannes Strobl
          <br />
          Website design, programming: Laura Piccolo
          <br />
          Backend administration, second screen experience: Peter Ehses
          <br />
          Software development, postprocessing: Thomas Geissl
          <br />
          Hypercam design: Kim Albrecht, metaLAB (at) Harvard
          <br />
          <br />A commission by HAU Hebbel am Ufer.
          <br />
          Production: doublelucky productions and HAU Hebbel am Ufer (2021).
        </div>

        <p>Das Schweigen des Moses hat das letzte Wort.</p>
        {config.downloaderEnabled && <Downloader></Downloader>}
      </div>
    </section>
  );
};

// == Export
export default Vermodern;
