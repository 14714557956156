import React, { useEffect, useState, useRef } from "react";
import { useStore } from "../../store";
import Seed from "../Seed";

// == Import
import "./styles.scss";
import { growthStages } from "../../constants";
import Image from "../Utils/image";
import Video from "../Utils/Video";
import Wrapper from '../Utils/Wrapper'
// import Flower from "./Flower";

// == Composant
const Blumen = ({ moveCursor }) => {
  const devMode = useStore((state) => state.devMode);

  const alertWindow = () => {
    window.alert("Blooming process started");
  };

  const seedAmount = useStore((state) => state.seedCount);

  const videoG = useStore((state) => state.videoG);
  const videoH = useStore((state) => state.videoH);

  // const videoRefs = [useRef(null), useRef(null)];

  // const flowerSrc = [
  //   "33c5f165-c46f-4d7a-b101-8d2a3b851a69",
  //   "a3bb915c-a81d-4527-982a-56a4360eca02",
  //   "baf135b6-5734-4219-ae89-c02b3a56a718",
  //   "ee4835af-6568-4dab-a119-fda4642dfd2c",
  //   "c318616d-3af6-4b1a-aac0-2f428834188c",
  // ];

  const flowerSrc = [
    "https://a.storyblok.com/f/107910/500x300/162cf7be66/floweranimated_small.png",
    "https://a.storyblok.com/f/107910/1000x1000/fc90c89c2f/flower1big_animated.png",
    "https://a.storyblok.com/f/107910/600x400/409bd2fba2/flower_test_animated.png",
    "https://a.storyblok.com/f/107910/1000x1000/915cb98a3f/phyloxxx.png",
    "https://a.storyblok.com/f/107910/1000x1000/040d46866c/phyloxx.png",
  ];

  useEffect(() => {
    for (let i = 0; i < 500; i++) {
      const randLeft = Math.random() * 5000;
      const randTop = Math.random() * 5000;
      let newFlower = document.createElement("img");
      const scale = Math.random() * 3;
      const source = i % 5;
      const randMargLeft = Math.random() * 5000 - 2500;
      const randMargTop = Math.random() * 3000 - 1500;
      newFlower.src = flowerSrc[source];
      // newFlower.src = `https://api.tangled-garden.net/assets/${flowerSrc[source]}`;
      newFlower.style.left = `${randLeft}px`;
      newFlower.style.top = `${randTop}px`;
      newFlower.className = `blumen__garden__flower blumen__garden__flower--${i} blumen__garden__flower--src${source}`;
      newFlower.setAttribute("data-135000", "margin-left:0px;margin-top:0px;position:absolute");
      newFlower.setAttribute(
        "data-145000",
        `margin-left:${randMargLeft}px;margin-top:${randMargTop}px;position:absolute`
      );
      newFlower.style.transform = `scale(${scale})`;
      document.querySelector(".blumen__garden").appendChild(newFlower);
    }
  }, []);

  // useEffect(() => {
  //   if (videoG) {
  //     videoRefs[0].current.play();
  //     videoRefs[0].current.muted = false;
  //   } else {
  //     videoRefs[0].current.pause();
  //   }
  // }, [videoG]);
  // useEffect(() => {
  //   if (videoH) {
  //     videoRefs[1].current.play();
  //     videoRefs[1].current.muted = false;
  //   } else {
  //     videoRefs[1].current.pause();
  //   }
  // }, [videoH]);

  return (
    <section
      className="section section--blumen"
      id="blumen"
      data-0="display:none;pointer-events:none;position:fixed;top:0;left:0"
      data-110000="display:none;pointer-events:none;position:fixed;top:0;left:0"
      data-110001="display:block;pointer-events:all;position:fixed;top:0;left:0"
      data-155000="display:block;pointer-events:all;position:fixed;top:0;left:0"
      data-155001="display:none;pointer-events:none;position:fixed;top:0;left:0"
    >
      {/* <div
        className="blumen__background3__container"
        data-0="display:none;opacity:1;"
        data-110000="display:block;opacity:0;"
        data-113000="display:block;opacity:1;"
        data-125000="display:block;opacity:0.5;"
        data-135000="display:block;opacity:0;"
      >
        <div className="blumen__background-3" />
      </div> */}

      <div
        className="blumen__title"
        data-0="display:none;opacity:1;"
        data-110000="display:block;opacity:0;"
        data-111000="display:block;opacity:1;"
        data-114000="display:block;opacity:1;"
        data-114000="display:block;opacity:0;"
      >
        BLÜHEN
      </div>
      <div
        className="blumen__background-bright"
        onMouseMove={(event) => moveCursor(event)}
        data-0="display:none"
        data-112000="display:block;opacity:0;"
        data-114000="display:block;opacity:0.95;"
        data-118000="display:block;opacity:0.95;"
        data-118100="display:block;opacity:0;"
        data-127000="display:block;opacity:0;"
        data-128000="display:block;opacity:0.95;"
        data-131000="display:block;opacity:0.95;"
        data-131100="display:block;opacity:0;"
      />
      <div
        className="blumen__text1"
        data-0="display:none"
        data-114000="display:block;opacity:0;"
        data-116000="display:block;opacity:1;"
        data-117000="top:0vh;left:0vw"
        data-118600="top:-100vh;left:-100vw"
      >
        Ich bin von tausend Vorstellung getrieben,
        <br />
        beglückt und gepeinigt.
        <br />
        Das Pflanzenreich rast wieder einmal in meinem Gemüthe,
        <br />
        ich kann es nicht einen Augenblick loswerden...
      </div>
      <div
        className="blumen__round"
        data-0="display:none"
        data-116000="display:block"
        data-118000="display:none"
        data-128000="display:block"
        data-130000="width:20vh;height:20vh;margin-left:0vh;margin-top:0vh"
        data-131000="width:300vh;height:300vh;margin-left:-150vh;margin-top:-150vh"
        data-132000="display:block;opacity:1"
        data-132500="display:block;opacity:0"
      />
      <div
        className="blumen__text2"
        data-0="display:none"
        data-115000="display:flex;opacity:0;"
        data-117000="display:flex;opacity:1;"
        data-117800="display:flex;opacity:1;"
        data-118000="display:flex;opacity:1;"
        data-119000="display:flex;opacity:0;"
        data-127500="display:flex;opacity:0;"
        data-128500="display:flex;opacity:1;"
        data-130100="display:flex;opacity:1;"
        data-130800="display:flex;opacity:0;"
      >
        <p>
          When pixels glow,
          <br />
          are they glowing for us
          <br />
          or for themselves?
        </p>
      </div>

      <div
        className="userphoto userphoto--4"
        data-0="display:none"
        data-118000="display:block;top:0vh;left:0vw"
        data-123000="display:block;top:0vh;left:0vw"
        data-126000="display:block;top:-10vh;left:100vw"
      >
        <div className="userphoto__info userphoto__info--4" />
        <Seed growthStage={growthStages.bluehen}></Seed>
      </div>

      <div
        className="blumen__text3"
        data-0="display:none"
        data-122000="display:block;top:0vh;left:-50vw"
        data-124000="display:block;top:0vh;left:0vw"
        data-128000="display:block;top:10vh;left:100vw"
        data-129000="display:none"
      >
        <p>Schau auf den Code.</p>
        <p>Versunken in einem langen</p>
        <p>tiefen Drogentraum</p>
      </div>

      <div
        className="blumen__code"
        data-0="display:none"
        data-136000="display:block;top:-10vh;left:-100vw"
        // data-125000="display:block;top:10vh;left:20vw"
        data-137000="display:block;top:50vh;left:50vw"
        data-138000="display:block;top:50vh;left:50vw"
        data-139000="display:block;top:100vh;left:100vw"
        onClick={alertWindow}
      >
        <Image id="f1be47eb-e856-46f3-9873-489f015529c9" />
      </div>
      <div
        className="blumen__video1"
        data-0="display:none"
        data-130800="display:block;opacity:0"
        data-131300="display:block;opacity:1"
        data-134000="display:block;opacity:1;top:0vh;left:0vw;width:100vw;height:100vh"
        data-135000="display:block;opacity:1;top:50vh;left:100vw;width:30vw;height:40vh"
      >

        <Wrapper fromStep={3} toStep={6}>
          <Video
            src={"https://a.storyblok.com/f/107910/x/c74e32f5f6/blute_bubblegirl1_1280.mp4"}
            poster={
              "https://a.storyblok.com/f/107910/1074x600/475585115f/bubblegirl1.png"
            }
            playing={videoG}
          ></Video> 
        </Wrapper>
      </div>
      <div
        className="blumen__garden"
        data-0="display:none;right:0px;bottom:0px;opacity:0"
        data-134000="display:block;right:0px;bottom:0px;opacity:0"
        data-135000="display:block;right:0px;bottom:0px;opacity:1"
        data-145000="display:block;right:-5000px;bottom:-5000px"
      >
        {/* {[...Array(n)].map((e, i) => (
          <Flower i={i} key={`flower--${i}`} />
        ))} */}
      </div>
      <p
          className="blumen__garden__p blumen__garden__p--1"
          data-133000="margin-top: 40vh;margin-left:-50vw"
          data-136000="margin-top: 30vh;margin-left:100vw"
        >
          Schau auf die Blume.
          <br />
          Versunken in einem langen,
          <br />
          tiefen Drogentraum
        </p>
        <p
          className="blumen__garden__p blumen__garden__p--2"
          data-135000="margin-top: 0vh;margin-left:-50vw"
          data-136000="margin-top: 80vh;margin-left:100vw"
        >
          Life is not perceived except when in operation.
          <br />
          And is never manifest except in its effects.
        </p>
        <p
          className="blumen__garden__p blumen__garden__p--3"
          data-139000="margin-top: -30vh;margin-left:30vw"
          data-142000="margin-top: 50vh;margin-left:100vw"
        >
          Das ist fast alles, was wir sehen;
          <br />
          aber hier wie in allem, fängt das
          <br />
          wahre und große Wunder dort erst an,
          <br />
          wo unser Blick ein Ende hat.
        </p>
        <p
          className="blumen__garden__p blumen__garden__p--4"
          data-143000="margin-top: 70vh;margin-left:-30vw"
          data-145000="margin-top: 100vh;margin-left:100vw"
        >
          Honeytrap
        </p>
      <div
        className="blumen__text4"
        data-140000="left: 3000px"
        data-152000="left: -10000px"
      >
        Hier wird Honig geboren
      </div>
      <div
        className="blumen__video2"
        data-0="display:none"
        data-147000="display:block"
        data-148000="display:block;left:-10vw;top:-100vh"
        data-150000="display:block;left:0vw;top:0vh;opacity:1"
        data-152500="display:block;left:0vw;top:0vh;opacity:1"
        data-153500="display:block;left:0vw;top:0vh;opacity:0.3"
        data-154500="display:block;left:0vw;top:0vh;opacity:0.3"
        data-155500="display:block;left:0vw;top:0vh;opacity:0.0"
        data-155501="display:none;left:0vw;top:0vh;opacity:0.0"
      >
        <Wrapper fromStep={3} toStep={6}>
          <Video
            src={"https://a.storyblok.com/f/107910/x/edc5e5c7e3/blute2_bubblegirl_1280.mp4"}
            poster={
              "https://a.storyblok.com/f/107910/1064x600/1832100b2a/bubblegirl2.png"
            }
            playing={videoH}
          ></Video>
        </Wrapper>
      </div>
      <div
        className="blumen__text6"
        data-153000="top: 100vh"
        data-155000="top: -200vh"
      >
        Das einzige Ziel ist: der Immobilität zu entkommen. Dem Schicksal der
        Wurzel durch die Blüte zu entrinnen. Dem Kabel, dem Silikon, dem
        Framework durch den Code zu entkommen. Sich freizumachen, die enge
        Sphäre zu zerbrechen, den Raum zu besiegen, sich einem anderen
        Naturreich zu nähern, in eine lebende Welt einzudringen und dabei eine
        neue Welt entstehen zu lassen.
        <br />
        <br />
        Und dass dies den Daten wie den Pflanzen gelingt, ist das nicht ebenso
        erstaunlich, als wenn wir uns zusammentäten, um außerhalb der
        Zeitschranken zu leben oder uns in eine andere Welt aufzuschwingen, die
        von den lastenden Gesetzen der Materie befreit ist?
      </div>

      {/* 
      
      Schau auf die Blume. Versunken in einem langen, tiefen Drogentraum
      Life is not perceived except when in operation. And is never manifest except in its effects. 
      Honeytrap
      
      */}

      {/* <div className="blumen__flotter blumen__flotter1"
      ><Image id="f782a947-24c3-425e-bbd3-bf2644ad0a6b" /></div>
      <div className="blumen__flotter blumen__flotter2"
      ><Image id="0da428a0-bea6-4402-b451-2fbbcae7f782" /></div>
      <div className="blumen__flotter blumen__flotter3"
      ><Image id="9b899420-0efa-45bf-9074-bc7c276e3e9b" /></div> */}

      <div className="blumen__floater blumen__floater--1"
        data-0="left:-50vw;top:50vh;"
        data-124000="left:-50vw;top:50vh;"
        data-128000="left:100vw;top:40vh;"
      >
        <Image id="4988a70f-dbd4-4bd0-ab0b-5acde5044e5a" />
      </div>
      <div className="blumen__floater blumen__floater--2"
        data-0="left:30vw;top:-70vh;"
        data-143000="left:30vw;top:-70vh;"
        data-145000="left:40vw;top:100vh;"
      >
        <img src="https://a.storyblok.com/f/107910/600x600/9127505c0b/interfaceloop-animated.png" />
      </div>
      <div className="blumen__floater blumen__floater--3"
        data-0="left:60vw;top:-30vh;"
        data-146000="left:60vw;top:-30vh;"
        data-148000="left:20vw;top:100vh;"
      >
        <Image id="5f1fbc78-1443-4a88-a038-6aba425ad89a" />
      </div>
    </section>
  );
};

// == Export
export default Blumen;
