import React, { useEffect, useRef } from "react";
// import styled from "styled-components";
// const Container = styled.div``;

// TODO: would it make sense to use react-player, or any other smart video player
export default function Video({
  src,
  poster,
  playing,
}) {
  const ref = useRef(null);
  useEffect(() => {
    if (playing) {
      ref.current.play();
      ref.current.muted = false;
    } else {
      ref.current.pause();
    }
    ref.current.onended = function() {
      ref.current.load();
    };
  }, [playing]);



  return (
    <video ref={ref} poster={poster} preload="auto">
      <source src={src}></source>
    </video>
  );
}
