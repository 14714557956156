import React from "react";
import Sketch from "react-p5";
import { RGBA_ASTC_10x10_Format } from "three";
import { v4 as uuidv4 } from "uuid";
import Container from "./Container";
import {
  getIndex,
  getRed,
  getGreen,
  getBlue,
  getBrightness,
  setColor,
  createSetup,
  loadPixelsAndIterate,
  getBase64,
  wrap,
  AnimationManager,
  makeNoiseMap,
} from "./helpers";

let frameCounter = 0;
const id = "id_" + uuidv4();
var hasFinished = false;
/* p5 global vars */

let anMan;
let img1ref;
let img2ref;
let imgXref;
let pos1;
let pos2;
let pos3;
let pos4;

function doDraw(p5, iterations = 2000) {
  imgXref.loadPixels();
  for (var i = 0; i < iterations; i++) {
    const change1 = p5.createVector(
      p5.floor(p5.random(-1, 2)),
      p5.floor(p5.random(-1, 2))
    );
    const change2 = p5.createVector(
      p5.floor(p5.random(-1, 2)),
      p5.floor(p5.random(-1, 2))
    );
    const change3 = p5.createVector(
      p5.floor(p5.random(-1, 2)),
      p5.floor(p5.random(-1, 2))
    );
    const change4 = p5.createVector(
      p5.floor(p5.random(-1, 2)),
      p5.floor(p5.random(-1, 2))
    );

    pos1.add(change1);
    pos2.add(change2);
    pos3.add(change3);
    pos3.add(change4);

    if (
      pos1.x < 0 ||
      pos1.x > p5.width - 1 ||
      pos1.y < 0 ||
      pos1.y > p5.height - 1
    ) {
      pos1.x = p5.width / 2;
      pos1.y = p5.height / 2;
    }

    const index = getIndex(img1ref, pos1.x, pos1.y);
    const index1 = getIndex(imgXref, pos1.x, pos1.y);
    imgXref.pixels[index1 + 0] = img1ref.pixels[index + 0];
    imgXref.pixels[index1 + 1] = img1ref.pixels[index + 1];
    imgXref.pixels[index1 + 2] = img1ref.pixels[index + 2];
    imgXref.pixels[index1 + 3] = 100;

    if (
      pos2.x < 0 ||
      pos2.x > p5.width - 1 ||
      pos2.y < 0 ||
      pos2.y > p5.height - 1
    ) {
      pos2.x = p5.width / 2;
      pos2.y = p5.height / 2;
    }

    const index2 = getIndex(img2ref, pos2.x, pos2.y);
    const index3 = getIndex(imgXref, pos2.x, pos2.y);
    imgXref.pixels[index3 + 0] = img2ref.pixels[index2 + 0];
    imgXref.pixels[index3 + 1] = img2ref.pixels[index2 + 1];
    imgXref.pixels[index3 + 2] = img2ref.pixels[index2 + 2];
    imgXref.pixels[index3 + 3] = 100;
    if (
      pos3.x < 0 ||
      pos3.x > p5.width - 1 ||
      pos3.y < 0 ||
      pos3.y > p5.height - 1
    ) {
      pos3.x = p5.width / 2;
      pos3.y = p5.height / 3;
    }

    const index6 = getIndex(img2ref, pos3.x, pos3.y);
    const index7 = getIndex(imgXref, pos3.x, pos3.y);
    imgXref.pixels[index7 + 0] = img2ref.pixels[index6 + 0];
    imgXref.pixels[index7 + 1] = img2ref.pixels[index6 + 1];
    imgXref.pixels[index7 + 2] = img2ref.pixels[index6 + 2];
    imgXref.pixels[index7 + 3] = 100;

    if (
      pos4.x < 0 ||
      pos4.x > p5.width - 1 ||
      pos4.y < 0 ||
      pos4.y > p5.height - 1
    ) {
      pos4.x = p5.width / 2;
      pos4.y = p5.height / 3;
    }

    const index8 = getIndex(img2ref, pos4.x, pos4.y);
    const index9 = getIndex(imgXref, pos4.x, pos4.y);
    imgXref.pixels[index9 + 0] = img2ref.pixels[index8 + 0];
    imgXref.pixels[index9 + 1] = img2ref.pixels[index8 + 1];
    imgXref.pixels[index9 + 2] = img2ref.pixels[index8 + 2];
    imgXref.pixels[index9 + 3] = 100;
  }
  imgXref.updatePixels();
}

/**
 * take two images, intersect them by noise, fake animate the process
 *
 * @param {boolean} active
 * @param {*} src
 * @param {int} width
 * @param {int} height
 * @param {function} onFinished
 * @param {int} runtime - milliseconds until animation is "finished"
 * @param {int} frameDivider - animation is called every nth frame
 * @param {int} noiseScalar - noise Size Scalar
 */
export default function P5({
  active,
  src,
  width,
  height,
  onFinished,
  src2,
  runtime = 40000,
  frameDivider = 1,
}) {
  const preload = (p5) => {
    // fetch imgs, handle only one img somewhat gracefully
    img1ref = p5.loadImage(src);
    if (src2) {
      img2ref = p5.loadImage(src2);
    } else {
      img2ref = img1ref;
    }
  };

  const setup = (p5, canvasParentRef) => {
    // barebones p5
    // createSetup(width, height, src)(p5, canvasParentRef);
    p5.createCanvas(width, height).parent(canvasParentRef);
    // console.log(canvasParentRef);
    p5.pixelDensity(1);
    img1ref.resize(width, height);
    img2ref.resize(width, height);
    
    // // images and buffers
    imgXref = p5.createGraphics(width, height);
    img1ref.loadPixels();
    img2ref.loadPixels();
    imgXref.loadPixels();

    pos1 = p5.createVector(width / 2, height / 2);

    pos2 = p5.createVector(width / 2, height / 2);

    pos3 = p5.createVector(width / 2, height / 3);

    pos4 = p5.createVector(width / 2, height / 3);

    p5.image(img1ref, 0, 0, width, height);
    // animation management
    anMan = new AnimationManager(p5, runtime);
    p5.updatePixels();
  };
  const draw = (p5) => {
    if (!active) return 0; // if not active skip block
    if (frameDivider != 1) {
      // check if framedivider is in effect
      frameCounter++;
      if (frameCounter % frameDivider != 0) {
        // if not on "active" frame return
        return 1;
      }
    }
    anMan.start();
    let animationHead = anMan.update();

    /* 
    creative part start
    */

    doDraw(p5, 2000);
    p5.image(imgXref, 0, 0, width, height);

    // p5.updatePixels(); // has been moved to iterater function
    /*
    creative part end
    */

    if (animationHead >= 1 && !hasFinished) {
      hasFinished = true;
      // if past runtime trigger finished event
      if (onFinished) {
        onFinished(getBase64(id));
      }
      return 2;
    }
    return 3;
  };
  const debugDraw = (p5) => {
    // console.log({
    //   active,
    //   src,
    //   src2,
    // });
    // console.log(draw(p5));
    draw(p5);
  };

  return (
    <Container width={width} height={height} id={id}>
      <Sketch preload={preload} setup={setup} draw={debugDraw} />
    </Container>
  );
}
