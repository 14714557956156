import * as THREE from "three";
import React, { Suspense, useRef, useState } from "react";
import { Canvas, useFrame, useLoader, useThree } from "react-three-fiber";
import { useAspect } from "@react-three/drei";
import "./RaysOfBlindingLightMaterial";
// import { PostProcessing } from "./PostProcessing";

// https://www.shadertoy.com/view/lsf3Dn

let frameCounter = 0;
function Effect({ active, src, width, height, onFinished }) {
  const ref = useRef();
  const { gl, size } = useThree();
  const texture = useLoader(THREE.TextureLoader, src);

  const getImage = () => {
    let data = gl.domElement.toDataURL();
    return data;
  };
  useFrame((state) => {
    if (active) {
      if (!state.clock.running) {
        state.clock.start();
      }
    } else {
      state.clock.stop();
    }
    ref.current.iFrame = frameCounter;
    ref.current.iTime = state.clock.elapsedTime;
    ref.current.iResolution = new THREE.Vector3(size.width, size.height, 0.0);
    frameCounter++;

    if (frameCounter === 2000) {
      //   onFinished(getImage());
    }
  });

  const scale = useAspect(
    "cover", // Aspect ratio: cover | ... more to come, PR's welcome ;)
    width, // Pixel-width
    height, // Pixel-height
    1 // Optional scaling factor
  );

  return (
    <mesh onPointerMove={(e) => {}} scale={scale}>
      <planeBufferGeometry attach="geometry" />
      <raysOfBlindingLightMaterial
        ref={ref}
        attach="material"
        iChannel0={texture}
        active={active}
      />
    </mesh>
  );
}

export default function R3F({ active, src, width, height, onFinished }) {
  return (
    <Canvas
      style={{
        width: `${width}px`,
        height: `${height}px`,
        // position: "absolute",
      }}
      gl={{ preserveDrawingBuffer: true }}
    >
      <Suspense fallback={null}>
        <Effect
          active={active}
          src={src}
          width={width}
          height={height}
          onFinished={onFinished}
        />
      </Suspense>
      {/* {active && <PostProcessing></PostProcessing>} */}
    </Canvas>
  );
}
