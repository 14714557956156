import React, { useEffect, useState, useRef } from "react";
import { useStore } from "../../store";

// == Import
import "./styles.scss";
import Image from "../Utils/image";

// == Composant
const Man = ({ i }) => {
  const leftCoord = i * 3;
  const scale = i * 0.1 + 1;
  const manContainer = useRef(null);

  useEffect(() => {
    manContainer.current.setAttribute(
      `data-${175000 + i * 100}`,
      "display:block;position:absolute"
    );
    manContainer.current.setAttribute(
      `data-${180000 + i * 10}`,
      "display:none;position:absolute"
    );
  }, []);

  return (
    <div
      className="kreuzen__man__man"
      style={{ left: `${leftCoord}vw`, transform: `scale(${scale})` }}
      data-0="display:none;position:absolute"
      data-175000="display:none;position:absolute"
      onMouseOver={(event) => {
        event.target.remove();
      }}
      ref={manContainer}
    >
      <img src="https://a.storyblok.com/f/107910/600x600/f435427d35/digitalman_1.png"/>
    </div>
  );
};

export default Man;
