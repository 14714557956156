import React from "react";
import Sketch from "react-p5";
import { v4 as uuidv4 } from "uuid";
import Container from "../Container";
import { monteCarlo } from "../helpers";

let frameCounter = 0;
const id = "id_" + uuidv4();

let x, y;
export default function P5({ active, src, width, height, onFinished }) {
  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(width, height).parent(canvasParentRef);
    p5.pixelDensity(1);
    p5.updatePixels();
    p5.background(0);
    x = p5.width / 2;
    y = p5.height / 2;
  };
  const draw = (p5) => {
    if (!active) return;
    p5.color(255, 200, 20);
    p5.strokeWeight(2);
    p5.circle(Math.floor(x), Math.floor(y), 20);

    const rx = 26 - monteCarlo(p5, 0, 26);
    const ry = 26 - monteCarlo(p5, 0, 26);

    x += rx * p5.random(-1, 2);
    y += ry * p5.random(-1, 2);

    if (x > p5.width) {
      x = 0;
    } else if (x < 0) {
      x = p5.width;
    }

    if (y > p5.height) {
      y = 0;
    } else if (y < 0) {
      y = p5.height;
    }
  };

  return (
    <Container width={width} height={height} id={id}>
      <Sketch setup={setup} draw={draw} />
    </Container>
  );
}
