import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useStore } from "../store";
import { saveAs } from "file-saver";
import { getSeedByUser, getSeedsByUser, getAssetUrl } from "../api";

const Container = styled.div``;
const Button = styled.button`
  font-size: 24px;
  border-radius: 0;
  padding: 24px;
  margin-top: 50px;
`;

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

function getBase64Image(src, callback, outputFormat) {
  const img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    let dataURL;
    canvas.height = img.naturalHeight;
    canvas.width = img.naturalWidth;
    ctx.drawImage(img, 0, 0);
    dataURL = canvas.toDataURL(outputFormat);
    callback(dataURL);
  };

  img.src = src;
  if (img.complete || img.complete === undefined) {
    img.src =
      "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
    img.src = src;
  }
}

export default function Downloader() {
  const userId = useStore((state) => state.userId);
  const [download, setDownload] = useState(false);

  useEffect(() => {
    if (download) {
      getSeedsByUser(userId).then((data) => {
        data
          .filter((item) => item && item.id && item.growth_stage !== 0)
          .forEach((item) => {
            if (item && item.id) {
              // console.log(item);
              const url = getAssetUrl(item.id, 800, 800);
              getBase64Image(
                url,
                (data) => {
                  saveAs(
                    dataURItoBlob(data),
                    `${userId}_${item.growth_stage}.png`
                  );
                  // console.log(data);
                },
                "image/png"
              );
            }
          });
        setTimeout(() => {
          setDownload(false);
        }, 5000);
      });
    }
  }, [download]);

  const saen = useStore((state) => state.saen);
  const keimen = useStore((state) => state.keimen);
  const verzweigen = useStore((state) => state.verzweigen);
  const bluehen = useStore((state) => state.bluehen);
  const kreuzen = useStore((state) => state.kreuzen);
  const welken = useStore((state) => state.welken);
  return (
    <>
      <Container>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setDownload(true);
          }}
        >
          Feel free to collect your seed stages{" "}
          {download && <span>(in progress)</span>}
        </Button>
        {/* <ul>
          {saen && (
            <li>
              <a href={getAssetUrl(saen.id, 800, 800)} download>
                <img
                  src={getAssetUrl(saen.id, 800, 800)}
                  alt="W3Schools"
                  width="104"
                  height="142"
                />
              </a>
            </li>
          )}
        </ul> */}
      </Container>
    </>
  );
}
