import React, { useEffect, useState, useRef } from "react";
import { useStore } from "../../store";
import { growthStages } from "../../constants";
import Seed from "../Seed";
import SeedImage from "./SeedImage";
import { getAssetUrl } from "../../api";

import Video from "../Utils/Video";
import Wrapper from "../Utils/Wrapper";

// == Import
import "./styles.scss";
import "./breather.scss";
import $ from "jquery";
import Image from "../Utils/image";

let n = 500;

// == Composant
const Keimen = ({ checkSeeds }) => {
  const breather =
    '<div class="breathing--circle breathing--circle--a"></div><div class="breathing--circle breathing--circle--b"></div><div class="breathing--circle breathing--circle--c"></div></div>';

  const seedAmount = useStore((state) => state.seedCount);

  const createBreather = (event) => {
    let newBreather = document.createElement("div");
    newBreather.innerHTML = breather;
    newBreather.className = "breather";
    newBreather.style.left = event.clientX - 100 + "px";
    newBreather.style.top = event.clientY - 100 + "px";
    newBreather.style.animationDuration = Math.random() * 10 + 5;
    newBreather.style.transform = `scale(${Math.random() + 1})`;
    document.querySelector("body").appendChild(newBreather);
  };

  const videoC = useStore((state) => state.videoC);
  const videoD = useStore((state) => state.videoD);
  // const videoRefs = [useRef(null), useRef(null)];

  // useEffect(() => {
  //   if (videoC) {
  //     videoRefs[0].current.play();
  //     videoRefs[0].current.muted = false;
  //   } else {
  //     videoRefs[0].current.pause();
  //   }
  // }, [videoC]);
  // useEffect(() => {
  //   if (videoD) {
  //     videoRefs[1].current.play();
  //     videoRefs[1].current.muted = false;
  //   } else {
  //     videoRefs[1].current.pause();
  //   }
  // }, [videoD]);

  const seedSrc = [
    "2fb9ab66-23eb-4b46-ad2e-b9bd544b9f6e",
    "b7e48246-2007-4e6a-ba38-2d5059af6733",
    "46e9eea5-af74-46c1-9942-bec0fce9fa50",
    "57cdd319-bfa1-43d4-948b-0b5f900c81ac",
    "a32d04ec-97e5-46bd-ad24-5e79b52863c0",
    "b599171f-ce18-44d2-a584-ebd611ea9322",
    "ec881aa4-664b-4932-9435-f34499367fe5",
    "fd1b3438-1f71-4f79-8df8-2279915ecb57",
    "3231937c-d0ac-4eb5-a6c2-9a7a328a8dbe",
    "298dcafd-6241-4d7c-b460-2862613e7cf3",
    "642c7191-4e85-42f9-9471-d28a1e489a06",
  ];

  useEffect(() => {
    for (let i = 0; i < 100; i++) {
      const randLeft = Math.random() * 50 + 20;
      const randTop = Math.random() * 50 + 20;
      let newFlower = document.createElement("img");
      const scale = Math.random() * 2 + 1;
      const source = i % 11;
      const sound = (i % 4) + 1;
      newFlower.src = `https://api.tangled-garden.net/assets/${seedSrc[source]}`;
      newFlower.style.left = `${randLeft}%`;
      newFlower.style.top = `${randTop}%`;
      newFlower.addEventListener("mouseover", (event) => {
        event.target.remove();
        checkSeeds();
        document.querySelector(`#seedaudio--${sound}`).currentTime = 0;
        document.querySelector(`#seedaudio--${sound}`).play();
      });
      newFlower.className = `keimen__seed keimen__seed--${i} keimen__seed--src${source}`;
      newFlower.setAttribute("data-28000", "display:none;position:absolute");
      newFlower.setAttribute(
        `data-${27500 + i * 2}`,
        "display:block;position:absolute"
      );
      newFlower.style.transform = `scale(${scale})`;
      document.querySelector(".keimen__garden").appendChild(newFlower);
    }
  }, []);

  return (
    <section
      className="section section--keimen"
      id="keimen"
      data-0="display:none;pointer-events:none;position:fixed;top:0;left:0"
      data-22000="display:none;pointer-events:none;position:fixed;top:0;left:0"
      data-22001="display:block;pointer-events:all;position:fixed;top:0;left:0"
      data-65000="display:block;pointer-events:all;position:fixed;top:0;left:0"
      data-65001="display:none;pointer-events:none;position:fixed;top:0;left:0"
      onClick={(event) => {
        createBreather(event);
      }}
    >
      <div
        className="keimen__title"
        data-0="opacity:0"
        data-22000="opacity:0"
        data-22200="opacity:1"
        data-22600="opacity:1"
        data-23000="opacity:0"
      >
        KEIMEN
        <span>{seedAmount} seeds have been planted.</span>
      </div>
      <div
        className="keimen__slogan1 keimen__slogan1--a"
        data-22100="opacity:1;left:100vw;top:100vh"
        data-23100="opacity:1;left:0vw;top:0vh"
        data-25100="opacity:1;left:-100vw;top:-100vh"
      >
        Alles ist Blatt
      </div>
      <div
        className="keimen__slogan1 keimen__slogan1--b"
        data-22100="opacity:1;left:100vw;top:100vh"
        data-23100="opacity:1;left:0vw;top:0vh"
        data-25100="opacity:1;left:-110vw;top:-110vh"
      >
        Alles ist Blatt
      </div>
      <div
        className="keimen__slogan1 keimen__slogan1--c"
        data-24100="opacity:1;left:100vw;top:100vh"
        data-24900="opacity:1;left:0vw;top:-50vh"
        data-27100="opacity:1;left:-100vw;top:-200vh"
      >
        und durch diese Einfachheit
      </div>
      <div
        className="keimen__slogan1 keimen__slogan1--d"
        data-24100="opacity:1;left:100vw;top:100vh"
        data-24900="opacity:1;left:0vw;top:-50vh"
        data-27150="opacity:1;left:-100vw;top:-210vh"
      >
        und durch diese Einfachheit
      </div>
      <div
        className="keimen__slogan1 keimen__slogan1--e"
        data-26000="left:-50vw;top:100vh"
        data-27000="left:0vw;top:50vh"
        data-28000="left:100vw;top:-100vh"
      >
        wird die größte Mannigfaltigkeit möglich
      </div>
      <div
        className="keimen__slogan1 keimen__slogan1--f"
        data-26000="left:-50vw;top:100vh"
        data-27000="left:0vw;top:50vh"
        data-28000="left:100vw;top:-110vh"
      >
        wird die größte Mannigfaltigkeit möglich
      </div>
      <div
        className="keimen__slogan2"
        data-23100="display:none"
        data-24100="display:flex"
        data-27100="display:none"
      >
        <p>
          Alles ist 0 und 1
          <br />
          und durch diese Einfachheit
          <br />
          wird die größte Komplexität möglich
        </p>
      </div>
      <div
        className="keimen__hand"
        data-23100="left:-100vw;top:90vh"
        data-24100="left:100vw;top:30vh"
      >
        <Image id="b18f7cf3-8e58-4d80-af22-49f3e940e6cc" />
      </div>
      <div
        className="keimen__binary"
        data-23100="display:none"
        data-24100="display:block;"
        data-24200="display:none;"
        data-24300="display:block;"
        data-24500="display:none;"
        data-24550="display:block;"
        data-25100="display:block;"
        data-25200="display:none;"
        data-25450="display:block;"
        data-25500="display:none;"
        data-25550="display:block;"
        data-29100="display:none;"
        data-33000="display:block;"
        data-33300="display:none;"
        data-33600="display:block;"
        data-34000="display:none;"
      >
        <video loop autoPlay>
          {/* <source
            src={getAssetUrl('7d34694e-0a9b-4bc6-a532-20bb4f374da6')}
            type="video/mp4"
          /> */}
          <source src="https://tangled-garden.net/assets/binaryblue.mp4" />
        </video>
      </div>
      <div
        className="keimen__garden"
        data-27000="display:none"
        data-27100="display:block"
        data-28600="display:none"
        onMouseMove={checkSeeds}
      >
        {/* {n !== 0 && [...Array(n)].map((e, i) => (
          <SeedImage i={i} key={`seed--${i}`} />
        ))} */}
      </div>

      <div
        className="userphoto userphoto--2"
        data-27000="opacity:0"
        data-27100="opacity:1"
        data-34000="opacity:1"
        data-34100="opacity:0"
      >
        {/* PUT USER IMAGE 2 HERE AND REMOVE THE <img /> */}
        <div
          className="userphoto__info userphoto__info--2"
          // data-28000="display:none;opacity:1;position:absolute"
          // data-28001="display:block;opacity:1;position:absolute"
          // data-34000="opacity:1;display:block;position:absolute"
          // data-34100="opacity:0;display:none;position:absolute"
        />
        <Seed growthStage={growthStages.keimen} debug={false}></Seed>
      </div>

      <div
        className="keimen__datavid"
        data-33500="top:100vh"
        data-34000="top:-30vh"
        data-36000="left:10vw"
        data-38000="left:100vw"
      >
        <img src="https://a.storyblok.com/f/107910/340x340/1e1b80d800/binaryloop1.png" />
        <img src="https://a.storyblok.com/f/107910/340x340/1e1b80d800/binaryloop1.png" />
        <img src="https://a.storyblok.com/f/107910/340x340/1e1b80d800/binaryloop1.png" />
      </div>
      <div
        className="keimen__vege1"
        data-33800="display:none"
        data-33900="display:block;"
        data-36500="margin-left:0vw"
        data-38000="margin-left:-10vw"
        data-63000="opactiy:1;display:block"
        data-63000="opactiy:0;display:block;margin-top:0vh;"
        data-63500="margin-top:-100vh"
      >
        <Wrapper fromStep={1} toStep={6}>
          <Video
            src={
              "https://a.storyblok.com/f/107910/x/fa97831be6/keimen_part1_final_lobitrate.mp4"
            }
            poster="https://a.storyblok.com/f/107910/1066x600/f480d0b900/keimen1.png"
            playing={videoC}
          ></Video>
        </Wrapper>
      </div>
      <div
        className="keimen__vege2"
        data-45800="display:none"
        data-46900="display:block;"
        data-50000="left:80vw;top:100vw"
        data-52000="left:3vw;top:13vw;"
        data-63000="opactiy:1;display:block;margin-top:0vh;"
        data-63500="margin-top:-100vh"
      >
         <Wrapper fromStep={1} toStep={6}>
          <Video
            src={"https://a.storyblok.com/f/107910/x/8f1e09b90d/keimen_part2_final_lobitrate.mp4"}
            poster={
              "https://a.storyblok.com/f/107910/1066x600/98811635cc/keimen2.png"
            }
            playing={videoD}
          ></Video>
        </Wrapper>
      </div>
      <div
        className="keimen__computer1"
        data-46000="display:none;width:200px"
        data-48100="display:flex;width:200px"
        data-49600="display:flex;width:400px"
        data-49610="display:none;width:400px"
      >
        <div data-40100="width:10vh" data-43500="width:370vh">
          <Image id="f108034c-54e6-4278-899c-0e9d84885c59" />
        </div>
      </div>
      {/* <div className="keimen__background"
      data-34000="opacity:0;background-position: 0px 0px"
      data-35100="opacity:0.3;background-position: -20px -20px"
      data-45200="background-position: -1000px -1000px"
    /> */}
      <div
        className="keimen__text"
        data-47000="top:100vh"
        data-52000="top:-150vh"
      >
        <p>Information, die (f): von in formare = nicht geformt</p>
        <p>Pflanzen sind In-formation: </p>
        <p>In der Gestalt nicht festgelegt, </p>
        <p>realisieren sie ihre Form je nach Standpunkt und Umgebung.</p>
        <p>Samen wie Datenbänke sind pure Möglichkeit. </p>
        <p>
          Das Primat der Möglichkeit über determinierte Realisierung nennt man
          Virtualität.{" "}
        </p>
        <p>Vegetative Realität ist Virtuelle Realität.</p>
        <p>Virtuelle Realität ist Vegetative Realität. </p>
        <p>VR = VR</p>
        <p>VR</p>
      </div>

      <div
        className="keimen__slogan"
        data-0="opacity:1"
        data-63000="opacity:1"
        data-63001="opacity:0"
      >
        <p>INFORMATIONALIZE VEGETATION</p>
        <p>VEGETALIZE INFORMATION</p>
      </div>
      <div
        className="keimen__slogan-3"
        data-52000="top:100vh;left:-10vw"
        data-53000="top:-10vh;left:30vw"
      >
        austreiben
      </div>
      <div
        className="keimen__slogan-4"
        data-52500="top:100vh;left:60vw"
        data-53500="top:-30vh;left:10vw"
      >
        sprießen
      </div>
      <div
        className="keimen__slogan-5"
        data-53000="top:60vh;left:100vw"
        data-54000="top:50vh;left:-30vw"
      >
        geilen
      </div>
      <div
        className="keimen__slogan-6"
        data-53500="top:100vh;left:50vw"
        data-54500="top:-10vh;left:70vw"
      >
        THEY EAT LIGHT
      </div>
      <div
        className="keimen__slogan-6"
        data-53500="top:80vh;left:-20vw"
        data-54500="top:40vh;left:100vw"
      >
        THEY EAT LIGHT
      </div>
      <div
        className="keimen__slogan-6"
        data-53500="top:70vh;left:100vw"
        data-54500="top:10vh;left:-20vw"
      >
        THEY EAT LIGHT
      </div>

      <div
        className="keimen__slogan-7"
        data-55500="top:100vh;left:60vw"
        data-56500="top:-20vh;left:40vw"
      >
        Wir müssen die Lyrik der Flechten lesen.
      </div>
      <div
        className="keimen__slogan-8"
        data-55500="top:100vh;left:60vw"
        data-56500="top:-14vh;left:40vw"
      >
        Wir müssen die Lyrik der Festplatten lesen.
      </div>
      <div
        className="keimen__bibli"
        data-63000="display:none"
        data-63001="display:flex;opacity:1"
        data-64800="display:flex;opacity:1"
        data-65000="display:flex;opacity:0"
        data-65001="display:none;opacity:0"
      >
        <p>
          1 Garten + 1 Bibliothek
          <br />=
          <br />
          alles, was Du brauchst
        </p>
      </div>

      <div
        className="keimen__floater keimen__floater--1"
        data-0="left:-40vw;top:10vh"
        data-57000="left:-40vw;top:10vh"
        data-61000="left:120vw;top:70vh"
      >
       {/* SCIENTISTS */}
       <Image id="aa020c5c-83f1-43b9-be28-51e47e3fb7ad" />
      </div>
      <div
        className="keimen__floater keimen__floater--2"
        data-0="left:120vw;top:-20vh"
        data-62000="left:120vw;top:-20vh"
        data-65000="left:20vw;top:100vh"
      >
         {/* COMPUTER */}
         <Image id="f68e90d7-4e4a-4340-afb6-a2a8488fd5a4" />
      </div>
      <div
        className="keimen__floater keimen__floater--3"
        data-0="left:60vw;top:100vh"
        data-8000="left:60vw;top:100vh"
        data-10000="left:-30vw;top:60vh"
      >
        {/* AMPOULES */}
        <Image id="f71e4f65-a045-41ec-958e-b46ed93bc977" />
      </div>
      <div
        className="keimen__floater keimen__floater--4"
        data-0="left:80vw;top:-40vh"
        data-45000="left:80vw;top:-40vh"
        data-47000="left:-30vw;top:60vh"
      >
        {/* HAND */}
        <Image id="c982e28d-3b2b-428f-b8c0-a0466562c04e" />
      </div>

      <div
        className="keimen__floater keimen__floater--6"
        data-0="left:-100vw;top:50vh"
        data-63000="left:-100vw;top:50vh"
        data-65000="left:150vw;top:30vh"
      >
        {/* PUNCHCARD */}
        <Image id="27a276c9-9c3d-4997-b1c1-cc2c0db58c07" />
      </div>
      <div
        className="keimen__floater keimen__floater--5"
        data-0="opacity:0"
        data-63000="opacity:0"
        data-64000="opacity:1"
        data-64800="opacity:1"
        data-65000="opacity:0"
      >
        <img src="https://a.storyblok.com/f/107910/450x450/e4ddbbdfe6/turningthing.png" />
      </div>
    </section>
  );
};

// == Export
export default Keimen;
