import React from "react";
import Sketch from "react-p5";
import { RGBA_ASTC_10x10_Format } from "three";
import { v4 as uuidv4 } from "uuid";
import Container from "../Container";
import {
  getIndex,
  getRed,
  getGreen,
  getBlue,
  getBrightness,
  setColor,
  createSetup,
  loadPixelsAndIterate,
  getBase64,
  wrap,
  AnimationManager,
  makeNoiseMap,
  minmax,
} from "../helpers";

let frameCounter = 0;
const id = "id_" + uuidv4();
var hasFinished = false;

/* p5 global vars */

let anMan;
let img1ref;
let vectorTable;
let displaceIntensity;
let displacementMix;

function lightnessFromIndex(img, index) {
  //Gets a brightness value fromt he x,y pixel of an image.
  return (
    (img.pixels[index + 0] + img.pixels[index + 1] + img.pixels[index + 2]) / 3
  );
}

function slidePixels(img, x, y, dint = 1, dmix = 1) {
  //looks up the vector in the x,y position of LUT. an adds it to the current x,y positon and swaps pixels
  const slideDistance = vectorTable[x][y];
  const newX = (x + slideDistance.x * dint) | 0;
  const newY = (y + slideDistance.y * dint) | 0;
  swapPixels(img, x, y, newX, newY, dmix);
}
function swapPixels(img, x1, y1, x2, y2, dmix = 1) {
  // swaps the color of two pixels.

  var index1 = getIndex(img, x1, y1);
  var index2 = getIndex(img, x2, y2);

  if (dmix < 1) {
    const mixset2 = [
      img.pixels[index1 + 0] * dmix + img.pixels[index2 + 0] * (1 - dmix),
      img.pixels[index1 + 1] * dmix + img.pixels[index2 + 1] * (1 - dmix),
      img.pixels[index1 + 2] * dmix + img.pixels[index2 + 2] * (1 - dmix),
    ];
    const mixset1 = [
      img.pixels[index2 + 0] * dmix + img.pixels[index1 + 0] * (1 - dmix),
      img.pixels[index2 + 1] * dmix + img.pixels[index1 + 1] * (1 - dmix),
      img.pixels[index2 + 2] * dmix + img.pixels[index1 + 2] * (1 - dmix),
    ];

    img.pixels[index1 + 0] = mixset1[0];
    img.pixels[index1 + 1] = mixset1[1];
    img.pixels[index1 + 2] = mixset1[2];

    img.pixels[index2 + 0] = mixset2[0];
    img.pixels[index2 + 1] = mixset2[1];
    img.pixels[index2 + 2] = mixset2[2];
  } else {
    const r = img.pixels[index1 + 0];
    const g = img.pixels[index1 + 1];
    const b = img.pixels[index1 + 2];

    img.pixels[index1 + 0] = img.pixels[index2 + 0];
    img.pixels[index1 + 1] = img.pixels[index2 + 1];
    img.pixels[index1 + 2] = img.pixels[index2 + 2];

    img.pixels[index2 + 0] = r;
    img.pixels[index2 + 1] = g;
    img.pixels[index2 + 2] = b;
  }
}
function createLUT(p5, img) {
  //LUT, (look up table) is a 2D array of vectors. The voctros are generated from the lightness value of each pixel.
  vectorTable = [];
  for (var x = 0; x < img.width; x++) {
    let row = [];
    for (var y = 0; y < img.height; y++) {
      var vector = p5.createVector(1, 1);
      var l = lightnessFromIndex(img, getIndex(img, x, y));
      var heading = p5.map(l, 0, 255, 0, 6.28);
      vector.rotate(heading);
      vector.normalize();
      vector.setMag(6);
      row.push(vector);
    }
    vectorTable.push(row);
  }
}

/**
 * take two images, intersect them by noise, fake animate the process
 *
 * @param {boolean} active
 * @param {*} src
 * @param {int} width
 * @param {int} height
 * @param {function} onFinished
 * @param {int} runtime - milliseconds until animation is "finished"
 * @param {int} frameDivider - animation is called every nth frame
 * @param {int} noiseScalar - noise Size Scalar
 */
export default function P5({
  active,
  src,
  width,
  height,
  onFinished,
  runtime = 30000,
  frameDivider = 1,
  digitality = 1,
  intensity = 1,
}) {
  const preload = (p5) => {
    // fetch imgs, handle only one img somewhat gracefully
    img1ref = p5.loadImage(src);
  };

  const setup = (p5, canvasParentRef) => {
    // barebones p5
    // createSetup(width, height, src)(p5, canvasParentRef);
    p5.createCanvas(width, height).parent(canvasParentRef);

    p5.pixelDensity(1);

    // animation management
    anMan = new AnimationManager(p5, runtime);

    img1ref.loadPixels();
    displaceIntensity = digitality;
    displacementMix = intensity;
    p5.image(img1ref, 0, 0, width, height);
    createLUT(p5, img1ref);
    // imgScale = {
    //   w: imageScale,
    //   h: img1ref.height*imageScale/img1ref.width
    // }
  };
  const draw = (p5) => {
    if (!active) return 0; // if not active skip block
    if (frameDivider != 1) {
      // check if framedivider is in effect
      frameCounter++;
      if (frameCounter % frameDivider != 0) {
        // if not on "active" frame return
        return 1;
      }
    }
    anMan.start();
    let animationHead = anMan.update();

    /* 
        creative part start
        */
    for (var x = 0; x < img1ref.width; x++) {
      for (var y = 0; y < img1ref.height; y++) {
        slidePixels(
          img1ref,
          x,
          y,
          animationHead * displaceIntensity,
          animationHead * displacementMix
        );
      }
    }
    img1ref.updatePixels();
    p5.image(img1ref, 0, 0, width, height);

    /*
        creative part end
        */

    if (animationHead >= 1 && !hasFinished) {
      hasFinished = true;
      // if past runtime trigger finished event
      if (onFinished) {
        onFinished(getBase64(id));
      }
      return 2;
    }
    return 3;
  };
  const debugDraw = (p5) => {
    // console.log({
    //   active,
    //   src,
    //   src2,
    // });
    // console.log(draw(p5));
    draw(p5);
  };

  return (
    <Container width={width} height={height} id={id}>
      <Sketch preload={preload} setup={setup} draw={debugDraw} />
    </Container>
  );
}
