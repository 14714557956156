import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useStore } from "../../store";
import Image from '../Utils/image'

// == Import
import "./styles.scss";

// == Composant
const Mobile = () => {
  
  return (
   <div className="mobile">
       <div className="mobile__plants" ><Image id="d589e744-92f4-4617-b8c6-7710fde86d98" /></div>
       <h1 className="mobile__title">The Garden<br/>of Tangled Data</h1>
       <p className="mobile__text">
       is not accessible from mobile devices,
        <br/>please visit us from your computer
        <span>— from 7pm to 11pm (GTM+1) —</span>
       </p>
   </div>
  );
};

// == Export
export default Mobile;
