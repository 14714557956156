import * as THREE from "three";
import React, { useMemo, useEffect, useRef } from "react";
import { useThree, useFrame, extend } from "react-three-fiber";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { SavePass } from "three/examples/jsm/postprocessing/SavePass";
import { CopyShader } from "three/examples/jsm/shaders/CopyShader";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { colorSpaceConverter, vertexShader } from "./shaders/helpers";

extend({ EffectComposer, ShaderPass, SavePass, RenderPass });

const triColorMix = {
  uniforms: {
    tDiffuse1: { value: null },
    tDiffuse2: { value: null },
    tDiffuse3: { value: null },
    u_active: { value: true },
    iFrame: { value: 0.0 },
    iResolution: { value: null },
    iMouse: { value: null },
    u_time: { value: 0.0 },
  },
  vertexShader,
  fragmentShader:
    `
    varying vec2 vUv;
    uniform int iFrame;
    uniform vec3 iResolution;
    uniform vec4 iMouse;
    uniform bool u_active;
    uniform float u_time;
    uniform sampler2D tDiffuse1;
    uniform sampler2D tDiffuse2;
    uniform sampler2D tDiffuse3;
    ` +
    colorSpaceConverter +
    `
    float hash(float n) { return fract(sin(n) * 1e4); }
    float fnoise(float x) {
        float i = floor(x);
        float f = fract(x);
        float u = f * f * (3.0 - 2.0 * f);
        return mix(hash(i), hash(i + 1.0), u);
    }
    

    void main() {
      float x = gl_FragCoord.x;
      float y = gl_FragCoord.y;


      vec4 del0 = texture2D(tDiffuse1, vUv);
      vec4 del1 = texture2D(tDiffuse2, vUv);
      vec4 del2 = texture2D(tDiffuse3, vUv);
      
      if(del1.g > 0.5){
        del1 = texture2D(tDiffuse2, vec2(vUv.x, vUv.y*u_time));
      }
      float alpha = min(min(del0.a, del1.a), del2.a);


      gl_FragColor = vec4(del0.r, del1.g, del2.b, alpha);
      gl_FragColor = vec4(sin(float(iFrame) / 1000.0), del1.g, del2.b, 1.0);

      // vec4 texel = texelFetch(tDiffuse1, ivec2(x,y), 0.0);

      if(x > iResolution.x/2.0){
        gl_FragColor = vec4(1.0, 1.0, del2.b, 1.0);
      }else{
        // gl_FragColor = texel;
      }
      // if(x < y){
      // gl_FragColor = vec4(1.0,1.0,0.0,0.3);//mix(del0.rgb, del1.rgb, u_time), alpha);
      // }else{

      // }
      // gl_FragColor = vec4(mix(del0.rgb, del1.rgb, u_time), alpha);
    }
  `,
};

let frameCounter = 0;
export function PostProcessing({ active }) {
  const composer = useRef();
  const savePass = useRef();
  const blendPass = useRef();
  const swap = useRef(false); // Whether to swap the delay buffers
  const { scene, gl, size, camera } = useThree();
  const { rtA, rtB, rtC } = useMemo(() => {
    const rtA = new THREE.WebGLRenderTarget(size.width, size.height);
    const rtB = new THREE.WebGLRenderTarget(size.width, size.height);
    const rtC = new THREE.WebGLRenderTarget(size.width, size.height);
    return { rtA, rtB, rtC };
  }, [size]);
  useEffect(() => void composer.current.setSize(size.width, size.height), [
    size,
  ]);
  useFrame(() => {
    // console.log("size", size);
    if (!savePass.current) return;
    // Swap render targets and update dependencies
    let delay1 = swap.current ? rtB : rtA;
    let delay2 = swap.current ? rtA : rtB;
    savePass.current.renderTarget = delay2;
    blendPass.current.uniforms["tDiffuse2"].value = delay1.texture;
    blendPass.current.uniforms["tDiffuse3"].value = delay2.texture;
    blendPass.current.uniforms["u_active"].value = active;
    blendPass.current.uniforms["u_time"].value = frameCounter / 10000;
    blendPass.current.uniforms["iFrame"].value = frameCounter;
    blendPass.current.uniforms["iResolution"].value = new THREE.Vector3(
      size.width,
      size.height,
      0.0
    );
    swap.current = !swap.current;
    composer.current.render();
    frameCounter++;
    frameCounter = frameCounter % 10000;
  }, 1);
  return (
    <effectComposer ref={composer} args={[gl]}>
      <renderPass attachArray="passes" scene={scene} camera={camera} />
      <shaderPass
        attachArray="passes"
        ref={blendPass}
        args={[triColorMix, "tDiffuse1"]}
        needsSwap={false}
      />
      <savePass attachArray="passes" ref={savePass} needsSwap={true} />
      {/* <shaderPass
        attachArray="passes"
        ref={savePass}
        args={[testShader, "tDiffuse1"]}
        needsSwap={true}
      /> */}
      <shaderPass attachArray="passes" args={[CopyShader]} />
    </effectComposer>
  );
}
