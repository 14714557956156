import React from "react";
import Sketch from "react-p5";
import { RGBA_ASTC_10x10_Format } from "three";
import { v4 as uuidv4 } from "uuid";
import Container from "../Container";
import {
    getIndex,
    getRed,
    getGreen,
    getBlue,
    getBrightness,
    setColor,
    createSetup,
    loadPixelsAndIterate,
    getBase64,
    wrap,
    AnimationManager,
    makeNoiseMap,
    minmax,
    getBrightnessVisual,
} from "../helpers";

let frameCounter = 0;
const id = "id_" + uuidv4();
var hasFinished = false;
/* p5 global vars */

let anMan;
let img1ref;
let vectorTable;
let canvas1;
let direction = true;

const getPx = (p5, x, y) => {
    const index = getIndex(p5, x, y);
    return [
        p5.pixels[index],
        p5.pixels[index + 1],
        p5.pixels[index + 2],
        p5.pixels[index + 3],
    ];
};
const setPx = (p5, x, y, px) => {
    const index = getIndex(p5, x, y);
    p5.pixels[index] = px[0] || 0;
    p5.pixels[index + 1] = px[1] || 0;
    p5.pixels[index + 2] = px[2] || 0;
    p5.pixels[index + 3] = px[3] || 255;
};

function checkPawn(img, x, y, dir) {
    var values = [
        getBrightnessVisual(img, x - 1, y + dir),
        getBrightnessVisual(img, x, y + dir),
        getBrightnessVisual(img, x + 2, y + dir),
    ];
    return values;
}

function swapPixelsInBuffer(x, y, nx, ny, src, buf) {
    var index1 = getIndex(src, x, y);
    var index2 = getIndex(src, nx, ny);
    buf.pixels[index2 + 0] = src.pixels[index1 + 0]; //Writes pixel 1 into buffer at position of pixel2
    buf.pixels[index2 + 1] = src.pixels[index1 + 1];
    buf.pixels[index2 + 2] = src.pixels[index1 + 2];

    buf.pixels[index1 + 0] = src.pixels[index2 + 0]; //Writes pixel 2 into buffer at position of pixel1
    buf.pixels[index1 + 1] = src.pixels[index2 + 1];
    buf.pixels[index1 + 2] = src.pixels[index2 + 2];
}
function halfSwapPixelsInBuffer(x, y, nx, ny, src, buf) {
    var index1 = getIndex(src, x, y);
    var index2 = getIndex(src, nx, ny);
    buf.pixels[index2 + 0] = src.pixels[index1 + 0]; //Writes pixel 1 into buffer at position of pixel2
    buf.pixels[index2 + 1] = src.pixels[index1 + 1];
    buf.pixels[index2 + 2] = src.pixels[index1 + 2];
}
function diminish(x, y, src, buf) {
    var index = getIndex(src, x, y);
    buf.pixels[index + 0] = src.pixels[index + 0] - 2; //Writes pixel 2 into buffer at position of pixel1
    buf.pixels[index + 1] = src.pixels[index + 1] - 1;
    buf.pixels[index + 2] = src.pixels[index + 2] - 2;
}

function doCheck(x, y, options) {
    const br = getBrightness(options.src, x, y);
    if (options.dir < 0) {
        // -- direction
        if (br > 230) {
            // check for light vals
            const pawn = checkPawn(options.src, x, y, -options.dir);
            let newX = x - 1 + pawn.indexOf(Math.max(...pawn));
            let newY = y - options.dir;
            newX = newX % options.src.width;
            newY = newY % options.src.height;
            halfSwapPixelsInBuffer(x, y, newX, newY, options.src, options.buf);
        } else if (br < 30) {
            diminish(x, y, options.src, options.buf); //diminsh() darkens all pixels by 1
        }
    } else {
        // ++ direction
        if (br < 60) {
            // check for dark vals
            const pawn = checkPawn(options.src, x, y, -options.dir);
            let newX = x - 1 + pawn.indexOf(Math.min(...pawn));
            let newY = y - options.dir;
            newX = newX % options.src.width;
            newY = newY % options.src.height;
            swapPixelsInBuffer(x, y, newX, newY, options.src, options.buf);
        }
    }
}

function doTransform(img, buffer) {
    buffer.loadPixels();
    let direction = 1;
    loadPixelsAndIterate(img, doCheck, direction, {
        dir: direction,
        src: img,
        buf: buffer,
    });
    direction = 0 - direction;
    loadPixelsAndIterate(img, doCheck, direction, {
        dir: direction,
        src: img,
        buf: buffer,
    });
    buffer.updatePixels();
}

/**
 * take two images, intersect them by noise, fake animate the process
 *
 * @param {boolean} active
 * @param {*} src
 * @param {int} width
 * @param {int} height
 * @param {function} onFinished
 * @param {int} runtime - milliseconds until animation is "finished"
 * @param {int} frameDivider - animation is called every nth frame
 * @param {int} noiseScalar - noise Size Scalar
 */
export default function P5({
    active,
    src,
    width,
    height,
    onFinished,
    runtime = 8000,
    frameDivider = 1,
}) {
    const preload = (p5) => {
        // fetch imgs, handle only one img somewhat gracefully
        img1ref = p5.loadImage(src);
    };

    const setup = (p5, canvasParentRef) => {
        // barebones p5
        // createSetup(width, height, src)(p5, canvasParentRef);
        p5.createCanvas(width, height).parent(canvasParentRef);

        p5.pixelDensity(1);

        // animation management
        anMan = new AnimationManager(p5, runtime);

        img1ref.loadPixels();
        p5.image(img1ref, 0, 0, width, height);

        canvas1 = p5.createGraphics(width, height);
        canvas1.image(img1ref, 0, 0, width, height);
        //canvas1.image(img1ref, 0, 0, width, height);
        // imgScale = {
        //   w: imageScale,
        //   h: img1ref.height*imageScale/img1ref.width
        //
    };
    const draw = (p5) => {
        if (!active) return 0; // if not active skip block
        if (frameDivider != 1) {
            // check if framedivider is in effect
            frameCounter++;
            if (frameCounter % frameDivider != 0) {
                // if not on "active" frame return
                return 1;
            }
        }
        anMan.start();
        let animationHead = anMan.update();

        /* 
            creative part start
            */
        doTransform(p5, canvas1);
        p5.image(canvas1, 0, 0, width, height);
        /*
            creative part end
            */
        if (animationHead >= 1 && !hasFinished) {
            hasFinished = true;
            // if past runtime trigger finished event
            if (onFinished) {
                onFinished(getBase64(id));
            }
            return 2;
        }
        return 3;
    };
    const debugDraw = (p5) => {
        // console.log({
        //   active,
        //   src,
        //   src2,
        // });
        // console.log(draw(p5));
        draw(p5);
    };

    return (
        <Container width={width} height={height} id={id}>
            <Sketch preload={preload} setup={setup} draw={debugDraw} />
        </Container>
    );
}
